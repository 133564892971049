import React, { useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Location } from '@nickel/core-ui/components/Icons'
import Spinner from '@nickel/core-ui/components/Spinner'
import { Type } from '@nickel/core-ui/components/Toaster/types'

import { LocalisationButton } from './styles'

import { toast } from 'src/components/ToasterContainer/ToasterContainer'
import { AppContext } from 'src/context'
import { AppActionsType } from 'src/context/types'
import { useDebounce } from 'src/hooks/useDebounce'
import { useMediaQuery } from 'src/hooks/useMediaQuery'
import { SnowplowAction, trackClickEvent } from 'src/utils/Tracking/utils'

export const Localisation = ({
    isMobileFullScreen,
    isResultSearch = false,
    closeDrawer
}: {
    isMobileFullScreen: boolean
    closeDrawer?: () => void
    isResultSearch?: boolean
}) => {
    const { t } = useTranslation('home')
    const isDesktop = useMediaQuery()

    const [isLocalisationFetching, setIsLocalisationFetching] = useState<boolean>(false)
    const loadingDebounce = useDebounce<boolean>(isLocalisationFetching, 300)

    const { dispatch } = useContext(AppContext)
    const _onGetLocalisation = () => {
        trackClickEvent(SnowplowAction.GEOLOCATION)
        setIsLocalisationFetching(true)
        navigator.geolocation.getCurrentPosition(
            (position) => {
                dispatch({
                    type: AppActionsType.SET_SEARCH_POSITION_MAP,
                    payload: {
                        point: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        },
                        withGeolocation: true
                    }
                })

                setIsLocalisationFetching(false)

                if (closeDrawer) {
                    closeDrawer()
                }
            },
            () => {
                toast(
                    {
                        label: t('localisationError'),
                        testId: 'localisationError',
                        type: Type.ERROR
                    },
                    document.querySelector('#widget-store-locator')?.shadowRoot
                )
                setIsLocalisationFetching(false)
            }
        )
    }

    return (
        <LocalisationButton
            className="alignSelf"
            Icon={loadingDebounce ? Spinner : Location}
            $isMobileFullScreen={isMobileFullScreen}
            $isResultSearch={isResultSearch}
            onClick={_onGetLocalisation}
            testId="localisation"
            text={t('geolocate')}
            variant={isDesktop || isResultSearch ? 'ghost' : 'secondary'}
        />
    )
}
