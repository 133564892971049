import styled, { createGlobalStyle, css } from 'styled-components'

import Button from '@nickel/core-ui/components/Button'
import { X } from '@nickel/core-ui/components/Icons'
import { References } from '@nickel/foundations/tokens/color'
import { Breakpoint } from '@nickel/foundations/tokens/layout'
import { FontSize } from '@nickel/foundations/tokens/typography'

export const GlobalStyle = createGlobalStyle<{ $hasOverlay: boolean }>`
    body {
        margin: 0;
    }

    #widget-store-locator {
        height: 100%;
        margin: 0;

        ${({ $hasOverlay }) => $hasOverlay && `overflow-y: hidden;`}
    }

    @keyframes react-loading-skeleton {
        100% {
            transform: translateX(100%);
        }
    }

    .react-loading-skeleton {
        --base-color: #ebebeb;
        --highlight-color: #f5f5f5;
        --animation-duration: 1.5s;
        --animation-direction: normal;



        background-color: var(--base-color);
        border-radius: 0.25rem;
        display: inline-flex;
        line-height: 1;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .react-loading-skeleton::after {
        animation-direction: var(--animation-direction);
        animation-duration: var(--animation-duration);
        animation-iteration-count: infinite;
        animation-name: react-loading-skeleton;
        animation-timing-function: ease-in-out;
        background-image: linear-gradient(
                90deg,
                var(--base-color),
                var(--highlight-color),
                var(--base-color)
        );
        background-repeat: no-repeat;
        content: ' ';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        transform: translateX(-100%);
    }
    
`

export const GlobalCustomStyle = styled.div<{ $hasOverlay: boolean }>`
    color: ${References.REF_NEUTRAL_N900};
    margin: 0 auto;
    max-width: 1200px;
    position: relative;

    ${({ $hasOverlay }) => {
        if ($hasOverlay)
            return css`
                z-index: 1000;
                overflow: hidden;
                height: 100dvh;
            `
    }}

    > * {
        box-sizing: border-box;
        font-family: 'Sarabun';

        @media (min-width: ${Breakpoint.MEDIUM}) {
            font-size: ${FontSize.MEDIUM};
        }
    }

    .alignSelf {
        align-self: unset;
        justify-self: unset;
        place-self: unset;
    }
`
export const HiddenDesktop = css`
    @media (min-width: ${Breakpoint.MEDIUM}) {
        display: none;
        visibility: hidden;
    }
`

export const DisplayOnDrawer = css`
    position: relative;
    z-index: 2;
`

export const WhiteButton = styled(Button)<{ $withBorder?: boolean }>`
    background-color: ${References.REF_NEUTRAL_N0};
    color: ${References.REF_NEUTRAL_N900};
    max-width: 100%;

    ${({ $withBorder }) => $withBorder && `border: solid 1px ${References.REF_NEUTRAL_N900};`}
    svg {
        fill: ${References.REF_NEUTRAL_N900};
    }

    &:hover {
        background-color: ${References.REF_SECONDARY_S400} !important;
        color: ${References.REF_NEUTRAL_N0} !important;

        svg {
            fill: ${References.REF_NEUTRAL_N0} !important;
        }
    }
`

export const CrossButton = styled(X)`
    &:hover {
        cursor: pointer;
    }
`
