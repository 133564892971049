import styled from 'styled-components'

import { References } from '@nickel/foundations/tokens/color'
import { Spacing } from '@nickel/foundations/tokens/layout'

import { BannerHeight } from 'src/components/Drawer/style'

export const SearchInputWrapper = styled.div<{ $isActive: boolean }>`
    width: 100%;

    // First div is search input wrapper

    .customSearchInput > div {
        border-radius: 53px;

        // Second div is input

        & > div {
            padding: ${Spacing.MEDIUM} ${Spacing.MEDIUM} ${Spacing.MEDIUM} ${Spacing.LARGE};
        }

        // Last svg in the glass icon

        svg:last-of-type {
            background-color: ${References.REF_NEUTRAL_N10};
            border-radius: ${Spacing.XXLARGE};
            height: ${Spacing.XXLARGE};
            padding: ${Spacing.MEDIUM};
            width: ${Spacing.XXLARGE};
        }
    }

    ${({ $isActive }) =>
        $isActive &&
        `
    background-color: ${References.REF_NEUTRAL_N10};
    border-radius: 10px 10px 0 0;
    height: calc(100dvh - ${BannerHeight});
    left: 0;
    padding: ${Spacing.XXLARGE} ${Spacing.XLARGE};
    position: fixed;
    top: ${BannerHeight};
    width: 100%;
    z-index: 2;
`}
`
