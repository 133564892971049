import React, { useContext, useMemo, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useCloseIfClickOutside } from '@nickel/core-ui/hooks/useCloseIfClickedOutside'

import { Chip } from '../../styles'

import { Filters } from './components/Filters'
import { MobileFilters } from './components/MobileFilters'
import { getFilterCount } from './domain/domain'
import { FilterChipContent, FilterChipWrapper, FiltersTitle, FiltersValue } from './styles'

import { AppContext } from 'src/context'
import { useMediaQuery } from 'src/hooks/useMediaQuery'
import { SnowplowAction, trackClickEvent } from 'src/utils/Tracking/utils'

export const FilterChip = ({
    filterDrawerOpen = false,
    resetDrawer
}: {
    filterDrawerOpen?: boolean
    resetDrawer?: () => void
}) => {
    const { t } = useTranslation('home')

    const [filtersVisible, setFiltersVisible] = useState<boolean>(false)
    const { state } = useContext(AppContext)

    const wrapperRef = useRef<HTMLInputElement>(null)
    useCloseIfClickOutside(wrapperRef, () => setFiltersVisible(false))

    const isDesktop = useMediaQuery()

    const _getFilterCount = useMemo(() => getFilterCount(state.filters), [state.filters])

    const _onClickFilter = () => {
        if (!filtersVisible) trackClickEvent(SnowplowAction.FILTER)
        setFiltersVisible((prev) => !prev)
    }

    if (isDesktop) {
        return (
            <FilterChipWrapper $isActive={filterDrawerOpen}>
                <Chip
                    data-testid="desktopFilter"
                    onClick={_onClickFilter}
                    onKeyPress={() => setFiltersVisible(!filtersVisible)}
                    ref={wrapperRef}
                    role="button"
                    tabIndex={0}
                >
                    {t('options')}
                    <FilterChipContent>
                        <FiltersTitle>
                            {t('filters')}
                            {filtersVisible && <Filters />}
                        </FiltersTitle>
                        {_getFilterCount > 0 && (
                            <FiltersValue data-testid="filterValue">{` (${_getFilterCount})`}</FiltersValue>
                        )}
                    </FilterChipContent>
                </Chip>
            </FilterChipWrapper>
        )
    }

    if (!isDesktop && filterDrawerOpen && resetDrawer) {
        return (
            <FilterChipWrapper $isActive={filterDrawerOpen}>
                <MobileFilters count={_getFilterCount} isActive={filterDrawerOpen} resetDrawer={resetDrawer} />
            </FilterChipWrapper>
        )
    }

    return null
}
