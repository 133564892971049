import styled from 'styled-components'

import { References } from '@nickel/foundations/tokens/color'
import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'
import { FontSize } from '@nickel/foundations/tokens/typography'

import { DisplayOnDrawer } from '../../../../styles'

import { BannerHeight } from 'src/components/Drawer/style'

export const FiltersWrapper = styled.div`
    color: ${References.REF_NEUTRAL_N900};
    display: flex;
    flex-direction: column;
    gap: ${Spacing.LARGE};
    text-align: left;
    ${DisplayOnDrawer};

    @media (min-width: ${Breakpoint.MEDIUM}) {
        background-color: ${References.REF_NEUTRAL_N0};
        border-radius: 8px;
        box-shadow: 0 4px 16px 0 rgba(25, 31, 41, 0.1);
        left: 0;
        margin-top: unset;
        min-width: max-content;
        padding: ${Spacing.MEDIUM} ${Spacing.LARGE};
        position: absolute;
        right: 0;
        top: calc(${FontSize.LARGE} + ${Spacing.MEDIUM} + 0.15rem);
    }
`

export const FilterChipWrapper = styled.div<{ $isActive: boolean }>`
    ${({ $isActive }) =>
        $isActive &&
        `
            border-radius: 10px 10px 0 0;
            height: calc(100dvh - ${BannerHeight});
            left: 0;
            position: fixed;
            padding: ${Spacing.XXLARGE} ${Spacing.XLARGE};
            top: ${BannerHeight};
            width: 100%;
            z-index: 2;
        `}
`
export const ToggleWrapper = styled.div`
    align-content: center;
    display: flex;
    gap: ${Spacing.LARGE};
`
export const MobileFiltersContainer = styled.div<{ $isActive: boolean }>`
    display: none;
    flex-direction: column;

    ${({ $isActive }) => $isActive && `display: flex;`}
    ${DisplayOnDrawer}
`

export const StickyButtons = styled.div`
    ${DisplayOnDrawer};
    background: ${References.REF_NEUTRAL_N0};
    border-radius: 8px 8px 0 0;
    bottom: 0;
    box-shadow: 0 4px 16px 0 rgba(25, 31, 41, 0.1);
    display: flex;
    left: 0;
    padding: ${Spacing.LARGE};
    position: fixed;
    width: 100vw;

    > button {
        flex: 1 1 0;
        justify-content: center;
        padding: 0;
    }
`

export const FilterButton = styled.div<{ $isIconButton: boolean }>`
    display: block;
    position: relative;
    ${({ $isIconButton }) => !$isIconButton && `margin-top: -${Spacing.LARGE};`}

    &:hover {
        cursor: pointer;
    }
`
export const FilterNotification = styled.div`
    align-items: flex-start;
    background-color: ${References.REF_BRAND_B100};
    border-radius: 64px;
    color: ${References.REF_NEUTRAL_N0};
    display: inline-flex;
    padding: calc(${Spacing.SMALL} / 2) ${Spacing.MEDIUM};
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transform: translate(50%, -50%);
    vertical-align: center;
`

export const FiltersTitle = styled.span`
    margin-left: ${Spacing.MEDIUM};
    position: relative;
`

export const FiltersValue = styled.span`
    color: ${References.REF_TERTIARY_BLUE100};
`

export const FilterChipContent = styled.div`
    text-decoration: underline ${References.REF_NEUTRAL_N900};
`
